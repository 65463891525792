import { createApp } from "vue";
import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";

const app = createApp();
app.use(Toast, {
  position: "bottom-right",
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 3000,
  transition: "Vue-Toastification__fade",
});
