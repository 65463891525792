import { createStore } from "vuex";

export default createStore({
  state: {
    appName: "Sellio Admin",
    backdrop: false,
    loader: false,
    defaultPerPage: { id: 25, label: "25" },
    sidebarExpanded: true,
    sideOptionsExpandend: false,
    blockOptionsActive: false,
    titleBarHeight: 0,
    submittng: false,
    vibrabergImageChange: false,
    vibrabergCopiedBlock: null,
    vibrabergCuttedBlock: null,
    priceDefinitions: [],
  },
  mutations: {
    TOGGLE_SIDEBAR(state, payload) {
      state.sidebarExpanded = payload;
    },
    TOGGLE_OPTIONS(state, payload) {
      state.sideOptionsExpandend = payload;
      state.backdrop = payload;
    },
    TOGGLE_BLOCK_OPTIONS(state, payload) {
      state.blockOptionsActive = payload;
      state.backdrop = payload;
    },
    SET_TITLEBAR_HEIGHT(state, payload) {
      state.titleBarHeight = payload;
    },
    TOGGLE_SUBMIT(state, payload) {
      state.submittng = payload;
    },
    VIBRABERG_IMAGE_CHANGE(state, payload) {
      state.vibrabergImageChange = payload;
    },
    VIBRABERG_COPIED_BLOCK(state, payload) {
      state.vibrabergCopiedBlock = payload;
    },
    VIBRABERG_CUTTED_BLOCK(state, payload) {
      state.vibrabergCuttedBlock = payload;
    },
    BACKDROP(state, payload) {
      state.sideOptionsExpandend = payload;
      state.blockOptionsActive = payload;
      state.backdrop = payload;
    },
    LOADER(state, payload) {
      state.loader = payload;
    },
    SET_PDS(state, payload) {
      state.priceDefinitions = payload;
    },
  },
  actions: {
    toggleSidebar: ({ commit }, payload) => commit("TOGGLE_SIDEBAR", payload),
    toggleOptions: ({ commit }, payload) => commit("TOGGLE_OPTIONS", payload),
    toggleBlockOptions: ({ commit }, payload) => commit("TOGGLE_BLOCK_OPTIONS", payload),
    setTitlebarHeight: ({ commit }, payload) => commit("SET_TITLEBAR_HEIGHT", payload),
    toggleSubmitting: ({ commit }, payload) => commit("TOGGLE_SUBMIT", payload),
    changingVibrabergImage: ({ commit }, payload) => commit("VIBRABERG_IMAGE_CHANGE", payload),
    setVibrabergCopiedBlock: ({ commit }, payload) => commit("VIBRABERG_COPIED_BLOCK", payload),
    setVibrabergCuttedBlock: ({ commit }, payload) => commit("VIBRABERG_CUTTED_BLOCK", payload),
    setBackdrop: ({ commit }, payload) => commit("BACKDROP", payload),
    setLoader: ({ commit }, payload) => commit("LOADER", payload),
    setPriceDefinitions: ({ commit }, payload) => commit("SET_PDS", payload),
  },
  modules: {},
});
