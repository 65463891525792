export default {
  loginEndpoint: "/login",
  registerEndpoint: "/register",
  refreshEndpoint: "/logout",
  logoutEndpoint: "/logout",

  tokenType: "Bearer",

  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
};
