<template>
  <div class="navigation-container">
    <nav id="navigation-links">
      <template v-for="(item, index) of navigationItems" :key="index">
        <div
          v-if="item.link && item.enabled"
          class="navigation-item"
          :class="[{ active: hash == item.link }, { 'has-submenu': item.submenu && item.submenu.length }]"
        >
          <router-link
            class="navigation-link"
            :class="[{ active: hash == item.link }, { 'has-submenu': item.submenu && item.submenu.length }]"
            :to="item.link"
            @click="replaceByHash(item.link)"
          >
            <div>
              <template v-if="navbarActive">
                <i v-if="item.icon" :class="`bi bi-${item.icon}`"></i>
              </template>
              <template v-else>
                <i v-if="item.icon" :class="`bi bi-${item.icon}`" v-b-tooltip.hover.right.html="item"></i>
              </template>
              <span>{{ item.title }}</span>
            </div>
          </router-link>
          <div class="submenu" v-if="item.submenu">
            <template v-for="(subItem, ix) of item.submenu" :key="ix">
              <router-link
                v-if="subItem.enabled"
                :to="subItem.link"
                class="navigation-link"
                @click="replaceByHash(item.link)"
              >
                <div>
                  <template v-if="navbarActive">
                    <i v-if="subItem.icon" :class="`bi bi-${subItem.icon}`"></i>
                  </template>
                  <template v-else>
                    <i v-if="subItem.icon" :class="`bi bi-${subItem.icon}`" v-b-tooltip.hover.right.html="subItem"></i>
                  </template>
                  <span>{{ subItem.title }}</span>
                </div>
              </router-link>
            </template>
          </div>
        </div>
        <div
          v-else-if="item.hash && item.enabled"
          class="navigation-item"
          :class="[{ active: hash == item.hash }, { 'has-submenu': item.submenu && item.submenu.length }]"
        >
          <div
            class="navigation-link"
            :class="[{ active: hash == item.link }, { 'has-submenu': item.submenu && item.submenu.length }]"
            @click="replaceByHash(item.hash)"
          >
            <div>
              <template v-if="navbarActive">
                <i v-if="item.icon" :class="`bi bi-${item.icon}`"></i>
              </template>
              <template v-else>
                <i v-if="item.icon" :class="`bi bi-${item.icon}`" v-b-tooltip.hover.right.html="item"></i>
              </template>
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="submenu" v-if="item.submenu">
            <template v-for="(subItem, ix) of item.submenu" :key="ix">
              <router-link v-if="subItem.enabled" :to="subItem.link" class="navigation-link">
                <div>
                  <template v-if="navbarActive">
                    <i v-if="subItem.icon" :class="`bi bi-${subItem.icon}`"></i>
                  </template>
                  <template v-else>
                    <i v-if="subItem.icon" :class="`bi bi-${subItem.icon}`" v-b-tooltip.hover.right.html="subItem"></i>
                  </template>
                  <span>{{ subItem.title }}</span>
                </div>
              </router-link>
            </template>
          </div>
        </div>
      </template>
    </nav>
  </div>
</template>

<script>
import { isUserLoggedIn } from "@/auth/utils";

export default {
  props: {
    navbarActive: { type: Boolean, default: false },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      loggedIn: isUserLoggedIn(),
      hash: "",
      navigationItems: [
        {
          link: "/dashboard",
          title: this.$t("navigations.dashboard"),
          icon: "speedometer",
          permission: "STATS",
        },
        {
          link: "/products",
          title: this.$t("navigations.products"),
          icon: "bag",
          submenu: [
            {
              link: "/products",
              title: this.$t("navigations.products"),
              icon: "bag",
              permission: "PRODUCTS",
            },
            {
              link: "/sets",
              title: this.$t("navigations.sets"),
              icon: "grid",
              permission: "SETS",
            },
            {
              link: "/categories",
              title: this.$t("navigations.categories"),
              icon: "diagram-2",
              permission: "CATEGORIES",
            },
            {
              link: "/category-tree",
              title: this.$t("navigations.categoryTree"),
              icon: "diagram-3",
              permission: "CATEGORIES",
            },
            {
              link: "/configurations",
              title: this.$t("navigations.configurations"),
              icon: "boxes",
              permission: "PRODUCT_CONFIGURATION",
            },
            {
              link: "/parameters",
              title: this.$t("navigations.parameters"),
              icon: "box",
              permission: "PARAMETERS",
            },
            {
              link: "/producers",
              title: this.$t("navigations.producers"),
              icon: "cpu",
              permission: "PRODUCERS",
            },
          ],
        },
        {
          link: "/orders",
          title: this.$t("navigations.orders"),
          icon: "cart",
          permission: "ORDERS",
        },
        {
          link: "/enquiries",
          title: this.$t("navigations.enquiries"),
          icon: "question-square",
          permission: "ENQUIRIES",
        },
        {
          link: "/customers",
          title: this.$t("navigations.customers"),
          icon: "people",
          submenu: [
            {
              link: "/customers",
              title: this.$t("navigations.customers"),
              icon: "person",
              permission: "CUSTOMERS",
            },
            {
              link: "/b2b-customers",
              title: this.$t("navigations.b2bcustomers"),
              icon: "person",
              permission: "B2B_CUSTOMERS",
            },
            {
              link: "/establishments",
              title: this.$t("navigations.establishments"),
              icon: "shop",
              permission: "B2B_CUSTOMERS",
            },
          ],
        },
        {
          link: "/pages",
          title: this.$t("navigations.cms"),
          icon: "vector-pen",
          submenu: [
            {
              link: "/pages",
              title: this.$t("navigations.pages"),
              icon: "file-text",
              permission: "WEB_PAGES",
            },
            {
              link: "/articles",
              title: this.$t("navigations.articles"),
              icon: "file-richtext",
              permission: "ARTICLES",
            },
            {
              link: "/article-categories",
              title: this.$t("navigations.articleCategories"),
              icon: "diagram-2",
              permission: "ARTICLE_CATEGORIES",
            },
            {
              link: "/articles-category-tree",
              title: this.$t("navigations.articlesCategoryTree"),
              icon: "diagram-3",
              permission: "ARTICLE_CATEGORIES",
            },
          ],
        },
        {
          link: "/banners",
          title: this.$t("navigations.marketing"),
          icon: "graph-up-arrow",
          submenu: [
            {
              link: "/banners",
              title: this.$t("navigations.banners"),
              icon: "images",
              permission: "BANNERS",
            },
            {
              link: "/banner-slides",
              title: this.$t("navigations.bannerSlides"),
              icon: "image",
              permission: "BANNER_SLIDES",
            },
            {
              link: "/reviews",
              title: this.$t("navigations.reviews"),
              icon: "pencil-square",
              permission: "REVIEWS",
            },
            {
              link: "/coupons",
              title: this.$t("navigations.coupons"),
              icon: "percent",
              permission: "COUPONS",
            },
            // {
            //   link: "/discounts",
            //   title: this.$t("navigations.discounts"),
            //   icon: "percent",
            //   permission: "DISCOUNTS",
            // },
          ],
        },
        {
          link: "/shops",
          title: this.$t("navigations.settings"),
          icon: "gear",
          submenu: [
            {
              link: "/shops",
              title: this.$t("navigations.shops"),
              icon: "shop",
              permission: "SHOPS",
            },
            {
              link: "/price-definitions",
              title: this.$t("navigations.priceDefinitions"),
              icon: "currency-exchange",
              permission: "PRICE_DEFINITION",
            },
            {
              link: "/payments",
              title: this.$t("navigations.payments"),
              icon: "credit-card",
              permission: "PAYMENTS",
            },
            {
              link: "/carriers",
              title: this.$t("navigations.carrier"),
              icon: "truck",
              permission: "CARRIERS",
            },
            {
              link: "/pick-up-points",
              title: this.$t("navigations.pickUpPoints"),
              icon: "pin-map",
              permission: "PICKUP_POINTS",
            },
            {
              link: "/zones",
              title: this.$t("navigations.zones"),
              icon: "map",
              permission: "ZONES",
            },
            {
              link: "/navigations",
              title: this.$t("navigations.navigation"),
              icon: "cursor",
              permission: "NAVIGATIONS",
            },
            {
              link: "/custom-resources",
              title: this.$t("navigations.customResources"),
              icon: "bounding-box",
              permission: "CUSTOM_RESOURCES",
            },
            {
              link: "/custom-resource-categories",
              title: this.$t("navigations.customResourceCategories"),
              icon: "diagram-2",
              permission: "CUSTOM_RESOURCE_CATEGORIES",
            },
            {
              link: "/custom-resources-category-tree",
              title: this.$t("navigations.customResourceCategoryTree"),
              icon: "diagram-3",
              permission: "CUSTOM_RESOURCE_CATEGORIES",
            },
            {
              link: "/variables",
              title: this.$t("navigations.variables"),
              icon: "gear-fill",
              permission: "ENVIRONMENT_VARIABLES",
            },
            {
              link: "/translations",
              title: this.$t("navigations.translations"),
              icon: "arrow-left-right",
              permission: "WEB_TRANSLATIONS",
            },
            {
              link: "/email/templates",
              title: this.$t("navigations.emailTemplates"),
              icon: "envelope",
              permission: "EMAIL_TEMPLATES",
            },
            {
              link: "/sms/templates",
              title: this.$t("navigations.smsTemplates"),
              icon: "chat-left",
              permission: "SMS_TEMPLATES",
            },
            {
              link: "/blocks",
              title: this.$t("navigations.blocks"),
              icon: "braces",
              permission: "JSON_BLOCKS",
            },
            {
              link: "/groups",
              title: this.$t("navigations.groups"),
              icon: "people",
              permission: "GROUPS",
            },
            {
              link: "/roles",
              title: this.$t("navigations.roles"),
              icon: "person",
              permission: "ROLES",
            },
          ],
        },
        {
          hash: "#logs",
          title: this.$t("navigations.logs"),
          icon: "code-slash",
          submenu: [
            {
              link: "/emails",
              title: this.$t("navigations.emails"),
              icon: "envelope-check",
              permission: "EMAILS",
            },
            {
              link: "/sms",
              title: this.$t("navigations.sms"),
              icon: "chat-left-dots",
              permission: "SMS",
            },
          ],
        },
        {
          link: "/exports",
          title: this.$t("navigations.exports"),
          icon: "download",
          permission: "EXPORTS",
        },
        {
          link: "/employees",
          title: this.$t("navigations.employees"),
          icon: "people",
          permission: "EMPLOYEES",
        },
        {
          link: "/logout",
          title: this.$t("navigations.logout"),
          icon: "door-open",
          enabled: true,
        },
      ],
    };
  },
  created() {
    if (this.userData) {
      this.traverse(this.navigationItems);
      for (const item of this.navigationItems) {
        if (item.link && item.submenu && !item.enabled) {
          const enabledSubItem = item.submenu.find((s) => s.enabled);
          if (enabledSubItem) {
            item.enabled = true;
            item.link = enabledSubItem.link;
          }
        }
        if (item.hash && item.submenu) {
          item.enabled = false;
          const enabledSubItem = item.submenu.find((s) => s.enabled);
          if (enabledSubItem) {
            item.enabled = true;
          }
        }
      }
    }
  },
  methods: {
    replaceByHash(hash) {
      this.hash = hash;
    },
    traverse(menu) {
      for (const item of menu) {
        if (item.enabled) {
          continue;
        }
        if (item.link) {
          let enabled = false;
          if (this.userData) {
            const existingPermission = this.userData.permissionRole?.permissions?.find(
              (p) => p.name === item.permission
            );
            if (existingPermission && existingPermission.LIST) {
              enabled = true;
            }
            if (["ROLE_SUPER_ADMIN", "ROLE_ADMIN"].includes(this.userData.role)) {
              enabled = true;
            }
            item.enabled = enabled;
          }
        }
        if (Object.prototype.hasOwnProperty.call(item, "submenu")) {
          this.traverse(item.submenu);
        }
      }
    },
  },
};
</script>
