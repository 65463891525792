<template>
  <div>
    <component :is="layout">
      <router-view :key="$route.fullPath" />
    </component>
    <div
      class="site-backdrop"
      :class="[{ active: backdropActive }]"
      @click="$store.dispatch('setBackdrop', false)"
    ></div>
    <div class="site-loader" :class="[{ active: loaderActive }]">
      <img src="@/assets/images/icons/loader.svg" width="100" height="100" />
    </div>
  </div>
</template>

<script>
import LayoutFull from "@/layouts/LayoutFull.vue";
import LayoutVertical from "@/layouts/LayoutVertical.vue";
import { computed } from "vue";
import { useHead } from "@vueuse/head";
import ListUtils from "@/mixins/ListUtils";
import { isUserLoggedIn } from "@/auth/utils";

export default {
  components: {
    LayoutFull,
    LayoutVertical,
  },
  mixins: [ListUtils],
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return "layout-vertical";
    },
    backdropActive() {
      return this.$store.state.backdrop;
    },
    loaderActive() {
      return this.$store.state.loader;
    },
  },
  created() {
    useHead({
      title: computed(() => {
        let title = "";
        if (this.$route.meta.pageTitle) {
          title = this.$t(this.$route.meta.pageTitle);
        }
        title += " - " + this.$store.state.appName;
        return title;
      }),
    });

    if (isUserLoggedIn()) {
      const pds = [];
      this.getPriceDefinitionsList(pds).then(() => {
        this.$store.dispatch("setPriceDefinitions", pds);
      });
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>
