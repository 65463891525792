<template>
  {{ value }}
</template>

<script>
export default {
  components: {},
  props: {
    promise: { type: Promise, required: true },
  },
  data() {
    return {
      value: null,
    };
  },
  watch: {
    async promise() {
      this.value = await this.promise;
    },
  },
  async created() {
    this.value = await this.promise;
  },
};
</script>
