import { createRouter as createVueRouter, createWebHistory } from "vue-router";
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";
import configHelper from "@/libs/configHelper";

const routes = [
  // Unauthorized
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: () => import("@/views/Auth403.vue"),
    meta: {
      pageTitle: "403 Unauthorized",
    },
  },
  // Unauthorized End
  // Home
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      pageTitle: "navigations.home",
      layout: "full",
    },
  },
  // Home End
  // Logout
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/Logout.vue"),
    meta: {
      pageTitle: "navigations.logout",
      layout: "full",
    },
  },
  // Logout End
  // Login
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      pageTitle: "navigations.login",
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  // Login End
  // Forgot Password
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: {
      pageTitle: "navigations.forgotPassword",
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  // Forgot Password End
  // Dashboard
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Admin/Dashboard/index.vue"),
    meta: {
      pageTitle: "navigations.dashboard",
      actionType: "LIST",
      permission: "STATS",
    },
  },
  // Dashboard End
  // Orders
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/Admin/Orders/list.vue"),
    meta: {
      pageTitle: "navigations.orders",
      breadcrumb: [
        {
          text: "navigations.orders",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "ORDERS",
    },
  },
  {
    path: "/add-order",
    name: "AddNewOrder",
    component: () => import("@/views/Admin/Orders/add.vue"),
    meta: {
      pageTitle: "order.new",
      breadcrumb: [
        {
          text: "order.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "ORDERS",
    },
  },
  {
    path: "/orders/:id/edit",
    name: "EditOrder",
    component: () => import("@/views/Admin/Orders/edit.vue"),
    meta: {
      pageTitle: "order.update",
      breadcrumb: [
        {
          text: "order.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "ORDERS",
    },
  },
  // Orders end
  // Enquiries
  {
    path: "/enquiries",
    name: "enquiries",
    component: () => import("@/views/Admin/Enquiries/list.vue"),
    meta: {
      pageTitle: "navigations.enquiries",
      breadcrumb: [
        {
          text: "navigations.enquiries",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "ENQUIRIES",
    },
  },
  {
    path: "/add-enquiry",
    name: "AddNewEnquiry",
    component: () => import("@/views/Admin/Enquiries/add.vue"),
    meta: {
      pageTitle: "enquiry.new",
      breadcrumb: [
        {
          text: "enquiry.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "ENQUIRIES",
    },
  },
  {
    path: "/enquiries/:id/edit",
    name: "EditEnquiry",
    component: () => import("@/views/Admin/Enquiries/edit.vue"),
    meta: {
      pageTitle: "enquiry.update",
      breadcrumb: [
        {
          text: "enquiry.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "ENQUIRIES",
    },
  },
  // Enquiries end
  // Pages
  {
    path: "/pages",
    name: "pages",
    component: () => import("@/views/Admin/Pages/list.vue"),
    meta: {
      pageTitle: "navigations.pages",
      breadcrumb: [
        {
          text: "navigations.pages",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "WEB_PAGES",
    },
  },
  {
    path: "/pages/:id/edit",
    name: "EditPage",
    component: () => import("@/views/Admin/Pages/edit.vue"),
    meta: {
      pageTitle: "page.update",
      breadcrumb: [
        {
          text: "page.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "WEB_PAGES",
    },
  },
  {
    path: "/add-page",
    name: "AddNewPage",
    component: () => import("@/views/Admin/Pages/add.vue"),
    meta: {
      pageTitle: "page.new",
      breadcrumb: [
        {
          text: "page.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "WEB_PAGES",
    },
  },
  // Pages End
  // Articles
  {
    path: "/articles",
    name: "articles",
    component: () => import("@/views/Admin/Articles/list.vue"),
    meta: {
      pageTitle: "navigations.articles",
      breadcrumb: [
        {
          text: "navigations.articles",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "ARTICLES",
    },
  },
  {
    path: "/articles/:id/edit",
    name: "EditArticle",
    component: () => import("@/views/Admin/Articles/edit.vue"),
    meta: {
      pageTitle: "article.update",
      breadcrumb: [
        {
          text: "article.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "ARTICLES",
    },
  },
  {
    path: "/add-article",
    name: "AddNewArticle",
    component: () => import("@/views/Admin/Articles/add.vue"),
    meta: {
      pageTitle: "article.new",
      breadcrumb: [
        {
          text: "article.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "ARTICLES",
    },
  },
  {
    path: "/articles-category-tree",
    name: "ArticlesCategoryTree",
    component: () => import("@/views/Admin/Categories/categoryTree.vue"),
    meta: {
      pageTitle: "article.categoryTree",
      breadcrumb: [
        {
          text: "article.categoryTree",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "ARTICLE_CATEGORIES",
    },
  },
  // Articles End
  // Article Categories
  {
    path: "/article-categories",
    name: "articleCategories",
    component: () => import("@/views/Admin/Categories/list.vue"),
    meta: {
      pageTitle: "navigations.articleCategories",
      breadcrumb: [
        {
          text: "navigations.articleCategories",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "ARTICLE_CATEGORIES",
    },
  },
  {
    path: "/article-categories/:id/edit",
    name: "EditArticleCategory",
    component: () => import("@/views/Admin/Categories/edit.vue"),
    meta: {
      pageTitle: "articleCategory.update",
      breadcrumb: [
        {
          text: "articleCategory.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "ARTICLE_CATEGORIES",
    },
  },
  {
    path: "/add-article-category",
    name: "AddNewArticleCategory",
    component: () => import("@/views/Admin/Categories/add.vue"),
    meta: {
      pageTitle: "articleCategory.new",
      breadcrumb: [
        {
          text: "articleCategory.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "ARTICLE_CATEGORIES",
    },
  },
  // Article Categories End
  // Navigations
  {
    path: "/navigations",
    name: "navigations",
    component: () => import("@/views/Admin/Navigations/list.vue"),
    meta: {
      pageTitle: "navigations.navigation",
      breadcrumb: [
        {
          text: "navigations.navigation",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "NAVIGATIONS",
    },
  },
  {
    path: "/navigations/:id/edit",
    name: "EditNavigation",
    component: () => import("@/views/Admin/Navigations/edit.vue"),
    meta: {
      pageTitle: "navigation.update",
      breadcrumb: [
        {
          text: "navigation.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "NAVIGATIONS",
    },
  },
  {
    path: "/add-navigation",
    name: "AddNewNavigation",
    component: () => import("@/views/Admin/Navigations/add.vue"),
    meta: {
      pageTitle: "navigation.new",
      breadcrumb: [
        {
          text: "navigation.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "NAVIGATIONS",
    },
  },
  // Navigations End
  // Customers
  {
    path: "/customers",
    name: "customers",
    component: () => import("@/views/Admin/Customers/list.vue"),
    meta: {
      pageTitle: "navigations.customers",
      breadcrumb: [
        {
          text: "navigations.customers",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "CUSTOMERS",
    },
  },
  {
    path: "/add-customer",
    name: "AddNewCustomer",
    component: () => import("@/views/Admin/Customers/add.vue"),
    meta: {
      pageTitle: "customer.new",
      breadcrumb: [
        {
          text: "customer.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "CUSTOMERS",
    },
  },
  {
    path: "/customers/:id/edit",
    name: "EditCustomer",
    component: () => import("@/views/Admin/Customers/edit.vue"),
    meta: {
      pageTitle: "customer.update",
      breadcrumb: [
        {
          text: "customer.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "CUSTOMERS",
    },
  },
  // Customers End
  // Employees
  {
    path: "/employees",
    name: "employees",
    component: () => import("@/views/Admin/Employees/list.vue"),
    meta: {
      pageTitle: "navigations.employees",
      breadcrumb: [
        {
          text: "navigations.employees",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "EMPLOYEES",
    },
  },
  {
    path: "/add-employee",
    name: "AddNewEmployee",
    component: () => import("@/views/Admin/Employees/add.vue"),
    meta: {
      pageTitle: "employee.new",
      breadcrumb: [
        {
          text: "employee.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "EMPLOYEES",
    },
  },
  {
    path: "/employees/:id/edit",
    name: "EditEmployee",
    component: () => import("@/views/Admin/Employees/edit.vue"),
    meta: {
      pageTitle: "employee.update",
      breadcrumb: [
        {
          text: "employee.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "EMPLOYEES",
    },
  },
  // Employees End
  // Banners
  {
    path: "/banners",
    name: "banners",
    component: () => import("@/views/Admin/Banners/list.vue"),
    meta: {
      pageTitle: "navigations.banners",
      breadcrumb: [
        {
          text: "navigations.banners",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "BANNERS",
    },
  },
  {
    path: "/banners/:id/edit",
    name: "EditBanner",
    component: () => import("@/views/Admin/Banners/edit.vue"),
    meta: {
      pageTitle: "banner.update",
      breadcrumb: [
        {
          text: "banner.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "BANNERS",
    },
  },
  {
    path: "/add-banner",
    name: "AddNewBanner",
    component: () => import("@/views/Admin/Banners/add.vue"),
    meta: {
      pageTitle: "banner.new",
      breadcrumb: [
        {
          text: "banner.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "BANNERS",
    },
  },
  {
    path: "/banner-slides",
    name: "BannerSlides",
    component: () => import("@/views/Admin/BannerSlides/list.vue"),
    meta: {
      pageTitle: "navigations.bannerSlides",
      breadcrumb: [
        {
          text: "navigations.bannerSlides",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "BANNER_SLIDES",
    },
  },
  {
    path: "/banner-slides/:id/edit",
    name: "EditBannerSlide",
    component: () => import("@/views/Admin/BannerSlides/edit.vue"),
    meta: {
      pageTitle: "slide.update",
      breadcrumb: [
        {
          text: "slide.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "BANNER_SLIDES",
    },
  },
  {
    path: "/add-banner-slide",
    name: "AddNewBannerSlide",
    component: () => import("@/views/Admin/BannerSlides/add.vue"),
    meta: {
      pageTitle: "slide.new",
      breadcrumb: [
        {
          text: "slide.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "BANNER_SLIDES",
    },
  },
  // Banners End
  // Products and Variants
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/Admin/Products/list.vue"),
    meta: {
      pageTitle: "navigations.products",
      breadcrumb: [
        {
          text: "navigations.products",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "PRODUCTS",
    },
  },
  {
    path: "/products/:id/edit",
    name: "EditProduct",
    component: () => import("@/views/Admin/Products/edit.vue"),
    meta: {
      pageTitle: "product.update",
      breadcrumb: [
        {
          text: "product.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "PRODUCTS",
    },
  },
  {
    path: "/add-product",
    name: "AddNewProduct",
    component: () => import("@/views/Admin/Products/add.vue"),
    meta: {
      pageTitle: "product.new",
      breadcrumb: [
        {
          text: "product.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "PRODUCTS",
    },
  },
  {
    path: "/products/:id/add-variant",
    name: "AddProductVariant",
    component: () => import("@/views/Admin/ProductVariants/add.vue"),
    meta: {
      pageTitle: "variant.new",
      breadcrumb: [
        {
          text: "variant.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "PRODUCTS",
    },
  },
  {
    path: "/products/:id/variant/:vid",
    name: "EditProductVariant",
    component: () => import("@/views/Admin/ProductVariants/edit.vue"),
    meta: {
      pageTitle: "variant.update",
      breadcrumb: [
        {
          text: "variant.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "PRODUCTS",
    },
  },
  {
    path: "/sets",
    name: "sets",
    component: () => import("@/views/Admin/Products/list.vue"),
    meta: {
      pageTitle: "navigations.sets",
      breadcrumb: [
        {
          text: "navigations.sets",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "SETS",
    },
  },
  // Products and Variants End
  // Producers
  {
    path: "/producers",
    name: "producers",
    component: () => import("@/views/Admin/Producers/list.vue"),
    meta: {
      pageTitle: "navigations.producers",
      breadcrumb: [
        {
          text: "navigations.producers",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "PRODUCERS",
    },
  },
  {
    path: "/producers/:id/edit",
    name: "EditProducer",
    component: () => import("@/views/Admin/Producers/edit.vue"),
    meta: {
      pageTitle: "producer.update",
      breadcrumb: [
        {
          text: "producer.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "PRODUCERS",
    },
  },
  {
    path: "/add-producer",
    name: "AddNewProducer",
    component: () => import("@/views/Admin/Producers/add.vue"),
    meta: {
      pageTitle: "producer.new",
      breadcrumb: [
        {
          text: "producer.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "PRODUCERS",
    },
  },
  // Producers End
  // Categories
  {
    path: "/categories",
    name: "categories",
    component: () => import("@/views/Admin/Categories/list.vue"),
    meta: {
      pageTitle: "navigations.categories",
      breadcrumb: [
        {
          text: "navigations.categories",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "CATEGORIES",
    },
  },
  {
    path: "/categories/:id/edit",
    name: "EditCategory",
    component: () => import("@/views/Admin/Categories/edit.vue"),
    meta: {
      pageTitle: "category.update",
      breadcrumb: [
        {
          text: "category.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "CATEGORIES",
    },
  },
  {
    path: "/add-category",
    name: "AddNewCategory",
    component: () => import("@/views/Admin/Categories/add.vue"),
    meta: {
      pageTitle: "category.new",
      breadcrumb: [
        {
          text: "category.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "CATEGORIES",
    },
  },
  {
    path: "/category-tree",
    name: "CategoryTree",
    component: () => import("@/views/Admin/Categories/categoryTree.vue"),
    meta: {
      pageTitle: "category.categoryTree",
      breadcrumb: [
        {
          text: "category.categoryTree",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "CATEGORIES",
    },
  },
  // Categories End
  // Parameters
  {
    path: "/parameters",
    name: "parameters",
    component: () => import("@/views/Admin/Parameters/list.vue"),
    meta: {
      pageTitle: "navigations.parameters",
      breadcrumb: [
        {
          text: "navigations.parameters",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "PARAMETERS",
    },
  },
  {
    path: "/parameters/:id/edit",
    name: "EditParameter",
    component: () => import("@/views/Admin/Parameters/edit.vue"),
    meta: {
      pageTitle: "parameter.update",
      breadcrumb: [
        {
          text: "parameter.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "PARAMETERS",
    },
  },
  {
    path: "/add-parameter",
    name: "AddNewParameter",
    component: () => import("@/views/Admin/Parameters/add.vue"),
    meta: {
      pageTitle: "parameter.new",
      breadcrumb: [
        {
          text: "parameter.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "PARAMETERS",
    },
  },
  // Parameters End
  // Configurations
  {
    path: "/configurations",
    name: "configurations",
    component: () => import("@/views/Admin/Configurations/list.vue"),
    meta: {
      pageTitle: "navigations.configurations",
      breadcrumb: [
        {
          text: "navigations.configurations",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "PRODUCT_CONFIGURATION",
    },
  },
  {
    path: "/configurations/:id/edit",
    name: "EditConfiguration",
    component: () => import("@/views/Admin/Configurations/edit.vue"),
    meta: {
      pageTitle: "configuration.update",
      breadcrumb: [
        {
          text: "configuration.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "PRODUCT_CONFIGURATION",
    },
  },
  {
    path: "/add-configuration",
    name: "AddNewConfiguration",
    component: () => import("@/views/Admin/Configurations/add.vue"),
    meta: {
      pageTitle: "configuration.new",
      breadcrumb: [
        {
          text: "configuration.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "PRODUCT_CONFIGURATION",
    },
  },
  // Configurations End
  // Coupons Start
  {
    path: "/coupons",
    name: "coupons",
    component: () => import("@/views/Admin/Coupons/list.vue"),
    meta: {
      pageTitle: "navigations.coupons",
      breadcrumb: [
        {
          text: "navigations.coupons",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "COUPONS",
    },
  },
  {
    path: "/coupons/:id/edit",
    name: "EditCoupon",
    component: () => import("@/views/Admin/Coupons/edit.vue"),
    meta: {
      pageTitle: "coupon.update",
      breadcrumb: [
        {
          text: "coupon.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "COUPONS",
    },
  },
  {
    path: "/add-coupon",
    name: "AddNewCoupon",
    component: () => import("@/views/Admin/Coupons/add.vue"),
    meta: {
      pageTitle: "coupon.new",
      breadcrumb: [
        {
          text: "coupon.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "COUPONS",
    },
  },
  // Coupons End
  // Reviews Start
  {
    path: "/reviews",
    name: "reviews",
    component: () => import("@/views/Admin/Reviews/list.vue"),
    meta: {
      pageTitle: "navigations.reviews",
      breadcrumb: [
        {
          text: "navigations.reviews",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "REVIEWS",
    },
  },
  {
    path: "/reviews/:id/edit",
    name: "EditReview",
    component: () => import("@/views/Admin/Reviews/edit.vue"),
    meta: {
      pageTitle: "review.update",
      breadcrumb: [
        {
          text: "review.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "REVIEWS",
    },
  },
  {
    path: "/add-review",
    name: "AddNewReview",
    component: () => import("@/views/Admin/Reviews/add.vue"),
    meta: {
      pageTitle: "review.new",
      breadcrumb: [
        {
          text: "review.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "REVIEWS",
    },
  },
  // Reviews End
  // Emails
  {
    path: "/emails",
    name: "Emails",
    component: () => import("@/views/Admin/Emails/list.vue"),
    meta: {
      pageTitle: "navigations.emails",
      breadcrumb: [
        {
          text: "navigations.emails",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "EMAILS",
    },
  },
  {
    path: "/emails/:id",
    name: "ViewEmails",
    component: () => import("@/views/Admin/Emails/detail.vue"),
    meta: {
      pageTitle: "email.viewEmail",
      breadcrumb: [
        {
          text: "email.viewEmail",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "EMAILS",
    },
  },
  // Emails End
  // Translations
  {
    path: "/translations",
    name: "Translations",
    component: () => import("@/views/Admin/Translations/edit.vue"),
    meta: {
      pageTitle: "navigations.translations",
      breadcrumb: [
        {
          text: "navigations.translations",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "WEB_TRANSLATIONS",
    },
  },
  // Translations end
  // Email templates
  {
    path: "/email/templates",
    name: "EmailTemplates",
    component: () => import("@/views/Admin/EmailTemplates/list.vue"),
    meta: {
      pageTitle: "navigations.emailTemplates",
      breadcrumb: [
        {
          text: "navigations.emailTemplates",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "EMAIL_TEMPLATES",
    },
  },
  {
    path: "/email/templates/:id/edit",
    name: "EditEmailTemplate",
    component: () => import("@/views/Admin/EmailTemplates/edit.vue"),
    meta: {
      pageTitle: "email.emailTemplateUpdate",
      breadcrumb: [
        {
          text: "email.emailTemplateUpdate",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "EMAIL_TEMPLATES",
    },
  },
  // Email template end
  // Sms
  {
    path: "/sms",
    name: "Sms",
    component: () => import("@/views/Admin/Sms/list.vue"),
    meta: {
      pageTitle: "navigations.sms",
      breadcrumb: [
        {
          text: "navigations.sms",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "SMS",
    },
  },
  {
    path: "/sms/:id",
    name: "ViewSms",
    component: () => import("@/views/Admin/Sms/detail.vue"),
    meta: {
      pageTitle: "sms.viewSms",
      breadcrumb: [
        {
          text: "sms.email",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "SMS",
    },
  },
  // Sms End
  // Sms templates
  {
    path: "/sms/templates",
    name: "SmsTemplates",
    component: () => import("@/views/Admin/SmsTemplates/list.vue"),
    meta: {
      pageTitle: "navigations.smsTemplates",
      breadcrumb: [
        {
          text: "navigations.smsTemplates",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "SMS_TEMPLATES",
    },
  },
  {
    path: "/sms/templates/:id/edit",
    name: "EditSmsTemplate",
    component: () => import("@/views/Admin/SmsTemplates/edit.vue"),
    meta: {
      pageTitle: "sms.smsTemplateUpdate",
      breadcrumb: [
        {
          text: "sms.smsTemplateUpdate",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "SMS_TEMPLATES",
    },
  },
  // Sms template end
  // CustomResources
  {
    path: "/custom-resources",
    name: "customResources",
    component: () => import("@/views/Admin/CustomResources/list.vue"),
    meta: {
      pageTitle: "navigations.customResources",
      breadcrumb: [
        {
          text: "navigations.customResources",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "CUSTOM_RESOURCES",
    },
  },
  {
    path: "/custom-resources/:id/edit",
    name: "EditCustomResource",
    component: () => import("@/views/Admin/CustomResources/edit.vue"),
    meta: {
      pageTitle: "customResource.update",
      breadcrumb: [
        {
          text: "customResource.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "CUSTOM_RESOURCES",
    },
  },
  {
    path: "/add-custom-resource",
    name: "AddNewCustomResource",
    component: () => import("@/views/Admin/CustomResources/add.vue"),
    meta: {
      pageTitle: "customResource.new",
      breadcrumb: [
        {
          text: "customResource.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "CUSTOM_RESOURCES",
    },
  },
  {
    path: "/custom-resources-category-tree",
    name: "CustomResourceCategoryTree",
    component: () => import("@/views/Admin/Categories/categoryTree.vue"),
    meta: {
      pageTitle: "customResource.categoryTree",
      breadcrumb: [
        {
          text: "customResource.categoryTree",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "CUSTOM_RESOURCES",
    },
  },
  // CustomResources End
  // CustomResources Categories
  {
    path: "/custom-resource-categories",
    name: "customResourceCategories",
    component: () => import("@/views/Admin/Categories/list.vue"),
    meta: {
      pageTitle: "navigations.customResourceCategories",
      breadcrumb: [
        {
          text: "navigations.customResourceCategories",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "CUSTOM_RESOURCE_CATEGORIES",
    },
  },
  {
    path: "/custom-resource-categories/:id/edit",
    name: "EditCustomResourceCategory",
    component: () => import("@/views/Admin/Categories/edit.vue"),
    meta: {
      pageTitle: "customResourceCategory.update",
      breadcrumb: [
        {
          text: "customResourceCategory.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "CUSTOM_RESOURCE_CATEGORIES",
    },
  },
  {
    path: "/add-custom-resource-category",
    name: "AddNewCustomResourceCategory",
    component: () => import("@/views/Admin/Categories/add.vue"),
    meta: {
      pageTitle: "customResourceCategory.new",
      breadcrumb: [
        {
          text: "customResourceCategory.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "CUSTOM_RESOURCE_CATEGORIES",
    },
  },
  // CustomResources Categories End
  // Carriers
  {
    path: "/carriers",
    name: "carriers",
    component: () => import("@/views/Admin/Carriers/list.vue"),
    meta: {
      pageTitle: "navigations.carriers",
      breadcrumb: [
        {
          text: "navigations.carriers",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "CARRIERS",
    },
  },
  {
    path: "/carriers/:id/edit",
    name: "EditCarrier",
    component: () => import("@/views/Admin/Carriers/edit.vue"),
    meta: {
      pageTitle: "carrier.update",
      breadcrumb: [
        {
          text: "carrier.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "CARRIERS",
    },
  },
  {
    path: "/add-carrier",
    name: "AddNewCarrier",
    component: () => import("@/views/Admin/Carriers/add.vue"),
    meta: {
      pageTitle: "carrier.new",
      breadcrumb: [
        {
          text: "carrier.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "CARRIERS",
    },
  },
  // Carriers End
  // Payments
  {
    path: "/payments",
    name: "payments",
    component: () => import("@/views/Admin/Payments/list.vue"),
    meta: {
      pageTitle: "navigations.payments",
      breadcrumb: [
        {
          text: "navigations.payments",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "PAYMENTS",
    },
  },
  {
    path: "/payments/:id/edit",
    name: "EditPayment",
    component: () => import("@/views/Admin/Payments/edit.vue"),
    meta: {
      pageTitle: "payment.update",
      breadcrumb: [
        {
          text: "payment.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "PAYMENTS",
    },
  },
  {
    path: "/add-payment",
    name: "AddNewPayment",
    component: () => import("@/views/Admin/Payments/add.vue"),
    meta: {
      pageTitle: "payment.new",
      breadcrumb: [
        {
          text: "payment.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "PAYMENTS",
    },
  },
  // Payments End
  // Zones
  {
    path: "/zones",
    name: "zones",
    component: () => import("@/views/Admin/Zones/list.vue"),
    meta: {
      pageTitle: "navigations.zones",
      breadcrumb: [
        {
          text: "navigations.zones",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "ZONES",
    },
  },
  {
    path: "/zones/:id/edit",
    name: "EditZone",
    component: () => import("@/views/Admin/Zones/edit.vue"),
    meta: {
      pageTitle: "zone.update",
      breadcrumb: [
        {
          text: "zone.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "ZONES",
    },
  },
  {
    path: "/add-zone",
    name: "AddNewZone",
    component: () => import("@/views/Admin/Zones/add.vue"),
    meta: {
      pageTitle: "zone.new",
      breadcrumb: [
        {
          text: "zone.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "ZONES",
    },
  },
  // Zones End
  // PickUpPoints
  {
    path: "/pick-up-points",
    name: "pickUpPoints",
    component: () => import("@/views/Admin/PickUpPoints/list.vue"),
    meta: {
      pageTitle: "navigations.pickUpPoints",
      breadcrumb: [
        {
          text: "navigations.pickUpPoints",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "PICKUP_POINTS",
    },
  },
  {
    path: "/pick-up-points/:id/edit",
    name: "EditPickUpPoint",
    component: () => import("@/views/Admin/PickUpPoints/edit.vue"),
    meta: {
      pageTitle: "pickUpPoint.update",
      breadcrumb: [
        {
          text: "pickUpPoint.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "PICKUP_POINTS",
    },
  },
  {
    path: "/add-pick-up-point",
    name: "AddNewPickUpPoint",
    component: () => import("@/views/Admin/PickUpPoints/add.vue"),
    meta: {
      pageTitle: "pickUpPoint.new",
      breadcrumb: [
        {
          text: "pickUpPoint.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "PICKUP_POINTS",
    },
  },
  // PickUpPoints End
  // Shops
  {
    path: "/shops",
    name: "shops",
    component: () => import("@/views/Admin/Shops/list.vue"),
    meta: {
      pageTitle: "navigations.shops",
      breadcrumb: [
        {
          text: "navigations.shops",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "SHOPS",
    },
  },
  {
    path: "/shops/:id/edit",
    name: "EditShop",
    component: () => import("@/views/Admin/Shops/edit.vue"),
    meta: {
      pageTitle: "shop.update",
      breadcrumb: [
        {
          text: "shop.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "SHOPS",
    },
  },
  {
    path: "/add-shop",
    name: "AddNewShop",
    component: () => import("@/views/Admin/Shops/add.vue"),
    meta: {
      pageTitle: "shop.new",
      breadcrumb: [
        {
          text: "shop.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "SHOPS",
    },
  },
  // Shops End
  // Variables
  {
    path: "/variables",
    name: "variables",
    component: () => import("@/views/Admin/Variables/list.vue"),
    meta: {
      pageTitle: "navigations.variables",
      breadcrumb: [
        {
          text: "navigations.variables",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "ENVIRONMENT_VARIABLES",
    },
  },
  // Variables End
  // PriceDefinitions
  {
    path: "/price-definitions",
    name: "priceDefinitions",
    component: () => import("@/views/Admin/PriceDefinitions/list.vue"),
    meta: {
      pageTitle: "navigations.priceDefinitions",
      breadcrumb: [
        {
          text: "navigations.priceDefinitions",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "PRICE_DEFINITION",
    },
  },
  // PriceDefinitions End
  // Blocks
  {
    path: "/blocks",
    name: "blocks",
    component: () => import("@/views/Admin/Blocks/list.vue"),
    meta: {
      pageTitle: "navigations.blocks",
      breadcrumb: [
        {
          text: "navigations.blocks",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "JSON_BLOCKS",
    },
  },
  {
    path: "/add-block",
    name: "AddNewBlock",
    component: () => import("@/views/Admin/Blocks/add.vue"),
    meta: {
      pageTitle: "block.new",
      breadcrumb: [
        {
          text: "block.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "JSON_BLOCKS",
    },
  },
  {
    path: "/blocks/:id/edit",
    name: "EditBlock",
    component: () => import("@/views/Admin/Blocks/edit.vue"),
    meta: {
      pageTitle: "block.update",
      breadcrumb: [
        {
          text: "block.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "JSON_BLOCKS",
    },
  },
  // Blocks End
  // Exports
  {
    path: "/exports",
    name: "Exports",
    component: () => import("@/views/Admin/Exports/index.vue"),
    meta: {
      pageTitle: "navigations.exports",
      breadcrumb: [
        {
          text: "navigations.exports",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "EXPORTS",
    },
  },
  // Exports End
  // User profile
  {
    path: "/profile",
    name: "EditProfile",
    component: () => import("@/views/Admin/UserProfile/edit.vue"),
    meta: {
      pageTitle: "user.update",
      breadcrumb: [
        {
          text: "user.update",
          active: true,
        },
      ],
    },
  },
  // User profile end
  // Roles
  {
    path: "/roles",
    name: "RolesList",
    component: () => import("@/views/Admin/Roles/list.vue"),
    meta: {
      pageTitle: "navigations.roles",
      breadcrumb: [
        {
          text: "navigations.roles",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "ROLES",
    },
  },
  {
    path: "/add-role",
    name: "AddNewRole",
    component: () => import("@/views/Admin/Roles/add.vue"),
    meta: {
      pageTitle: "role.new",
      breadcrumb: [
        {
          text: "role.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "ROLES",
    },
  },
  {
    path: "/roles/:id/edit",
    name: "EditRole",
    component: () => import("@/views/Admin/Roles/edit.vue"),
    meta: {
      pageTitle: "role.update",
      breadcrumb: [
        {
          text: "role.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "ROLES",
    },
  },
  // Roles end
  // B2BCustomer
  {
    path: "/b2b-customers",
    name: "B2BCustomersList",
    component: () => import("@/views/Admin/B2BCustomers/list.vue"),
    meta: {
      pageTitle: "navigations.b2bcustomers",
      breadcrumb: [
        {
          text: "navigations.b2bcustomers",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "B2B_CUSTOMERS",
    },
  },
  {
    path: "/establishments",
    name: "EstablishmentsList",
    component: () => import("@/views/Admin/B2BCustomers/list2.vue"),
    meta: {
      pageTitle: "navigations.establishments",
      breadcrumb: [
        {
          text: "navigations.establishments",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "B2B_CUSTOMERS",
    },
  },
  {
    path: "/add-b2b-customer",
    name: "AddNewB2BCustomer",
    component: () => import("@/views/Admin/B2BCustomers/add.vue"),
    meta: {
      pageTitle: "customer.new",
      breadcrumb: [
        {
          text: "customer.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "B2B_CUSTOMERS",
    },
  },
  {
    path: "/b2b-customers/:id/edit",
    name: "EditB2BCustomer",
    component: () => import("@/views/Admin/B2BCustomers/edit.vue"),
    meta: {
      pageTitle: "customer.update",
      breadcrumb: [
        {
          text: "customer.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "B2B_CUSTOMERS",
    },
  },
  // B2BCustomer end
  // Groups
  {
    path: "/groups",
    name: "GroupsList",
    component: () => import("@/views/Admin/Groups/list.vue"),
    meta: {
      pageTitle: "navigations.groups",
      breadcrumb: [
        {
          text: "navigations.groups",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "GROUPS",
    },
  },
  {
    path: "/add-group",
    name: "AddNewGroup",
    component: () => import("@/views/Admin/Groups/add.vue"),
    meta: {
      pageTitle: "group.new",
      breadcrumb: [
        {
          text: "group.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "GROUPS",
    },
  },
  {
    path: "/groups/:id/edit",
    name: "EditGroup",
    component: () => import("@/views/Admin/Groups/edit.vue"),
    meta: {
      pageTitle: "group.update",
      breadcrumb: [
        {
          text: "group.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "GROUPS",
    },
  },
  // Groups end
  // Discounts Start
  {
    path: "/discounts",
    name: "discounts",
    component: () => import("@/views/Admin/Discounts/list.vue"),
    meta: {
      pageTitle: "navigations.discounts",
      breadcrumb: [
        {
          text: "navigations.discounts",
          active: true,
        },
      ],
      actionType: "LIST",
      permission: "DISCOUNTS",
    },
  },
  {
    path: "/discounts/:id/edit",
    name: "EditDiscount",
    component: () => import("@/views/Admin/Discounts/edit.vue"),
    meta: {
      pageTitle: "discount.update",
      breadcrumb: [
        {
          text: "discount.update",
          active: true,
        },
      ],
      actionType: "UPDATE",
      permission: "DISCOUNTS",
    },
  },
  {
    path: "/add-discount",
    name: "AddNewDiscount",
    component: () => import("@/views/Admin/Discounts/add.vue"),
    meta: {
      pageTitle: "discount.new",
      breadcrumb: [
        {
          text: "discount.new",
          active: true,
        },
      ],
      actionType: "CREATE",
      permission: "DISCOUNTS",
    },
  },
  // Discounts End
];

// eslint-disable-next-line no-unused-vars
export const createRouter = (app) => {
  const router = createVueRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

  router.beforeEach(async (to, _, next) => {
    let isLoggedIn = isUserLoggedIn();
    const userData = getUserData();

    if (to.name === "logout") {
      return next();
    }

    if (userData && !["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_EMPLOYEE"].includes(userData.role)) {
      return next({ name: "logout" });
    }

    if (to.name === "unauthorized") {
      return next();
    }

    // Redirect to login if not logged in
    if (!isLoggedIn && to.name !== "login" && to.name !== "forgot-password") {
      return next({ name: "login" });
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData();
      next(getHomeRouteForLoggedInUser(userData ? userData : null, router));
    }

    // Router guards
    if (isLoggedIn) {
      let unauthorized = true;
      if (!["ROLE_SUPER_ADMIN", "ROLE_ADMIN"].includes(userData.role) && to.meta.permission) {
        const permissions = userData.permissionRole?.permissions;
        if (permissions && to.meta.permission && to.meta.actionType) {
          const concretePermition = permissions.find((p) => p.name === to.meta.permission);
          if (concretePermition[to.meta.actionType]) {
            unauthorized = false;
          }
        }
        if (unauthorized) {
          next("/unauthorized");
        }
      }
    }

    // Language guards
    const enabledLanguages = configHelper.getShopLanguages();
    if (to.query.translation && !enabledLanguages.includes(to.query.translation)) {
      const query = Object.assign({}, to.query);
      delete query.translation;
      to.query = query;
      next(to);
    }

    return next();
  });

  // ? For splash screen
  // Remove afterEach hook if you are not using splash screen
  router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
      appLoading.style.display = "none";
    }
  });

  return router;
};
