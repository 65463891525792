<template>
  <div class="mobile-top-bar">
    <router-link to="/" class="logo">
      <img src="@/assets/images/logo.svg" alt="" />
    </router-link>
    <navbar-top />
    <div class="logo-actions">
      <a v-if="loggedIn" target="_blank" :href="$helper.buildShowOnWebUrl($Shops)">
        <i class="bi bi-globe"></i>
      </a>
      <div class="burger" :class="[{ active: navbarActive }]" @click="toggleSidebar">
        <span />
        <span />
        <span />
      </div>
    </div>
  </div>
  <div class="navigation-backdrop" :class="[{ active: navbarActive }]" @click="toggleSidebar"></div>
</template>

<script>
import NavbarTop from "@/components/NavbarTop.vue";
import { isUserLoggedIn } from "@/auth/utils";

export default {
  components: {
    NavbarTop,
  },
  props: {
    navbarActive: { type: Boolean, default: false },
  },
  data() {
    return {
      loggedIn: isUserLoggedIn(),
    };
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch("toggleSidebar", !this.navbarActive);
    },
  },
};
</script>
