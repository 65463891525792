<template>
  <div class="navbar-top">
    <router-link v-if="user" to="/profile" class="user">
      <div class="user-circle">
        <div class="user-info">
          {{ user.givenName }} {{ user.familyName }}
          <span><promise-render :promise="getEnumTranslationString('ROLE', user.role)" /></span>
        </div>
        <div class="user-image">
          <img v-if="user.file" :src="user.file.url" />
          <span v-else>
            {{ user.familyName ? user.familyName[0] : "" }}{{ user.givenName ? user.givenName[0] : "" }}
          </span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import PromiseRender from "@/components/Common/PromiseRender.vue";
import ListUtils from "@/mixins/ListUtils";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    PromiseRender,
  },
  mixins: [ListUtils],
  data() {
    return {
      user: getUserData(),
      role: null,
    };
  },
};
</script>
