import jwtConfig from "./jwtConfig";

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // config <= Will be used by this service
  config = { ...jwtConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, apiURL) {
    this.axiosIns = axiosIns;

    this.config.loginEndpoint = apiURL + this.config.loginEndpoint;
    this.config.registerEndpoint = apiURL + this.config.registerEndpoint;

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // If token is present add it to request's Authorization Header
        // DO NOT ADD Authorization token if you try to login
        if (accessToken && config.url !== this.config.loginEndpoint) {
          config.headers.Authorization = `${this.config.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === 401) {
          if (response.data.message === "Invalid credentials.") {
            return Promise.reject(error);
          }
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            window.location = "/logout";
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${this.config.tokenType} ${accessToken}`;
              resolve(this.axiosIns(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken));
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.config.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.config.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.config.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.config.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return this.axiosIns.post(this.config.loginEndpoint, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.config.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.axiosIns.get(this.config.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }
}
