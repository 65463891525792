export const isUserLoggedIn = () => {
  return localStorage.getItem("userData") ? true : false;
};

export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

export const getHomeRouteForLoggedInUser = (userData, router) => {
  if (userData.role === "ROLE_ADMIN") return `/orders`;
  if (userData.role === "ROLE_EMPLOYEE") {
    let path = "/unauthorized";
    const permissions = userData.permissionRole?.permissions;
    if (permissions) {
      permissions.forEach((element) => {
        if (element.LIST) {
          router.getRoutes().forEach((route) => {
            if (route.meta.actionType === "LIST" && route.meta.permission === element.name) {
              path = route.path;
            }
          });
        }
        router.isReady();
      });
    }
    return path;
  }
  if (!userData) return "/logout";
  return { name: "login" };
};
